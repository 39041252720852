var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTableFiltered',{attrs:{"headers":_vm.headers,"items":_vm.workingHours,"sort-by":"approvedByDate","sort-desc":true,"item-key":"id","show-select":_vm.isStatusApproved},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title font-weight-light text-center pr-4 align-self-center",attrs:{"data-cy":"tableTitleWorkingHours"}},[_vm._v(" "+_vm._s(_vm.isStatusApproved ? "Godkjente timer" : "Timer til utbetaling")+" ")]),_c('v-spacer'),_c('BaseDatePicker',{attrs:{"label":"Fra"},on:{"input":function($event){return _vm.getWorkingHours()}},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}}),_c('BaseDatePicker',{attrs:{"label":"Til"},on:{"input":function($event){return _vm.getWorkingHours()}},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}}),(_vm.selectedRows.length > 0 && _vm.isStatusApproved)?_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary"},on:{"click":function($event){return _vm.submitWorkingHours()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" Lagre som CSV ")],1):_vm._e()]},proxy:true},{key:"item.date",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.formatLocalizedDate(value) : "")+" ")]}},{key:"item.approvedByDate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.formatLocalizedDate(value) : "")+" ")]}},{key:"item.comment",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-tooltip',{attrs:{"max-width":"50%","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"data-cy":"commentTooltip"}},'v-icon',attrs,false),on),[_vm._v("mdi-comment-text-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(value))])]):_vm._e()]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"data-cy":"syncUserInfoBtn"},on:{"click":function($event){return _vm.syncUserInfo(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-account-sync-outline")])]}}],null,true)},[_c('span',[_vm._v("Synkroniser med Visma")])])]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})}
var staticRenderFns = []

export { render, staticRenderFns }