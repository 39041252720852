




























import { api, binaryApi } from "@/api/api";
import { ApiGetWorkingHourDto } from "@/api/generated/Api";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { WorkingHourStatusEnum } from "@/shared/enums/WorkingHourStatus.enum";
import { formatLocalizedDate } from "@/shared/helpers/dateHelpers";
import { uniqueBy } from "@/shared/helpers/arrayHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import fileDownload from "js-file-download";

export default defineComponent({
  name: "ApprovedWorkingHoursPage",
  components: { BaseTableFiltered },
  setup() {
    const paymentPendingWorkingHours = ref<ApiGetWorkingHourDto[]>();

    const getApprovedWorkingHours = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        const initialWorkingHours = (
          await api.economy.getWorkingHoursAsync({
            IsSelfEmployed: false,
            Statuses: [WorkingHourStatusEnum.PaymentPending],
          })
        ).data;

        // Filter out unique files
        paymentPendingWorkingHours.value = uniqueBy(initialWorkingHours, (workingHour) => workingHour.fileId);
      });
    };

    const downloadWorkingHourCsv = async (workingHourEntry: ApiGetWorkingHourDto) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (!workingHourEntry.fileId || !workingHourEntry.fileName) {
          return;
        }
        const contractFile = (await binaryApi.file.downloadFileAsync(workingHourEntry.fileId, { format: "blob" })).data;
        fileDownload(contractFile, workingHourEntry.fileName);
      });
    };

    onMounted(() => getApprovedWorkingHours());

    return {
      headers,
      paymentPendingWorkingHours,
      downloadWorkingHourCsv,
      formatLocalizedDate,
    };
  },
});

const headers = [
  { text: "Fil", value: "fileName" },
  { text: "Dato", value: "fileGeneratedByDate" },
  { text: "Generert av", value: "fileGeneratedByUserFullName" },
  { text: "Handlinger", value: "actions" },
];
