

























import WorkingHoursTable from "@/components/administration/approvedWorkingHours/WorkingHoursTable.vue";
import WorkingHoursGeneratedFilesTable from "@/components/administration/approvedWorkingHours/WorkingHoursGeneratedFilesTable.vue";
import { defineComponent } from "@vue/composition-api";
import { WorkingHourStatusEnum } from "@/shared/enums/WorkingHourStatus.enum";

export default defineComponent({
  name: "ApprovedWorkingHoursPage",
  components: { WorkingHoursTable, WorkingHoursGeneratedFilesTable },
  setup() {
    return {
      WorkingHourStatusEnum,
    };
  },
});
