var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTableFiltered',{attrs:{"headers":_vm.headers,"items":_vm.paymentPendingWorkingHours,"sort-by":"fileGeneratedByDate","sort-desc":true},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title font-weight-light text-center pr-4 align-self-center",attrs:{"data-cy":"tableTitleWorkingHours"}},[_vm._v(" Genererte filer ")])]},proxy:true},{key:"item.fileGeneratedByDate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.formatLocalizedDate(value) : "")+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"data-cy":"downloadCsvBtn"},on:{"click":function($event){return _vm.downloadWorkingHourCsv(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-file-download-outline")])]}}],null,true)},[_c('span',[_vm._v("Last ned CSV")])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }