




















































import { api } from "@/api/api";
import { ApiGetWorkingHourDto } from "@/api/generated/Api";
import BaseDatePicker from "@/components/shared/date/BaseDatePicker.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { WorkingHourStatusEnum } from "@/shared/enums/WorkingHourStatus.enum";
import { formatLocalizedDate } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, onMounted, ref, computed } from "@vue/composition-api";

export default defineComponent({
  name: "WorkingHoursTable",
  components: { BaseTableFiltered, BaseDatePicker },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore<StoreState>();
    const selectedRows = ref<ApiGetWorkingHourDto[]>([]);
    const workingHours = ref<ApiGetWorkingHourDto[]>();
    const fromDate = ref("");
    const toDate = ref("");

    const isStatusApproved = computed(() => props.status === WorkingHourStatusEnum.Approved);

    const getWorkingHours = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        workingHours.value = (
          await api.economy.getWorkingHoursAsync({
            IsSelfEmployed: false,
            Statuses: [props.status],
            FromDate: fromDate.value,
            ToDate: toDate.value,
          })
        ).data;
      });
    };

    const syncUserInfo = async (workingHourEntry: ApiGetWorkingHourDto) => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        await api.user.syncErpPayrollEmployee(workingHourEntry.userId);
        openNotification(
          store,
          NotificationItemType.Success,
          `Bruker ${workingHourEntry.userFullName} synkronisert med Visma`
        );
        await getWorkingHours();
      });
    };

    const submitWorkingHours = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        await api.economy.setPaymentPendingWorkingHourAsync(selectedRows.value.map((workingHour) => workingHour.id));
        openNotification(store, NotificationItemType.Success, "CSV-fil lagret");
        await getWorkingHours();
      });
    };

    onMounted(() => getWorkingHours());

    const headers = computed(() => [
      { text: "Kurs-ID", value: "courseId" },
      { text: "Kursnavn", value: "courseName" },
      { text: "Lønnsart", value: "costPayType" },
      { text: "Kategori", value: "costTypeName" },
      { text: "Ressurs-ID", value: "userId" },
      { text: "Ressursnavn", value: "userFullName" },
      { text: "Ansnr.", value: "erpPayrollEmployeeId" },
      { text: "Avd.", value: "courseEconomyAccountingDepartment" },
      { text: "Prodnr.", value: "courseAreaErpProductNo" },
      { text: "Dato", value: "date" },
      { text: "Timer", value: "amount" },
      { text: "Pris", value: "courseContractLinePrice" },
      { text: "Kommentar", value: "comment" },
      { text: "Godkjent dato", value: "approvedByDate" },
      { text: "Godkjent av", value: "approvedByUserFullName" },
      ...(isStatusApproved.value ? [{ text: "Handlinger", value: "actions" }] : []),
    ]);

    return {
      headers,
      selectedRows,
      workingHours,
      fromDate,
      toDate,
      isStatusApproved,
      syncUserInfo,
      submitWorkingHours,
      getWorkingHours,
      formatLocalizedDate,
    };
  },
});
